import { ID, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import addCSSClass from "@/libs/layout-app/utils/addCSSClass"
import removeCSSClass from "@/libs/layout-app/utils/removeCSSClass"
import { Loading } from "@/libs/useful"
import { default as actionsMain } from '@/modules/pe-basic-module/data/actions'
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { IState, THEME_NAMES, useMainStore } from "@/settings/zustand"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { actions } from '../../data/actions'
import { CLEAR_CURRENT_PROJECT_ACTION, COMPARE_CURRENT_PROJECT_ACTION, GET_SINGLE_ACTIONS } from "../../data/actionTypes"
import { ICritery, IProject } from "../../data/interfaces"
import { useFestProjects } from "../../data/store"
import { createEmptyRating } from "../../data/updateCurrentRating"
import { useFestival } from "../../hooks/festival"
import { useMyProjects } from "../../hooks/project"
import ProjectMobileForm from "./project-mobile/ProjectMobileForm"
import ProjectScreenForm from "./ProjectScreenForm"

/* Protopia Ecosystem component */
export interface IProjectFormProps {
    parentMode?: boolean
}
const ProjectForm = (props: IProjectFormProps): JSX.Element => {
    const params = useParams()  
    const festId:ID = params.landId || "" 
    const id: ID = params.id  || ""
    const [isLoading, setIsLoading] = useState( true )
    const isLoading1 = useFestival(!props.parentMode, festId)    
    const {width} = useScreenSize()
    const theme: THEME_NAMES = useMainStore((state:IState) => state.theme)
    
    useMyProjects( festId )
    
    useEffect( () => { 
        const time = setInterval(() => { 
            const isBlockedUpdate:boolean = useFestProjects.getState().isBlockedUpdate
            if(!!id && !isBlockedUpdate) {
                actions( COMPARE_CURRENT_PROJECT_ACTION, { id, land_id: festId } )
            }
        }, 5000)
        return () => {  
            clearInterval( time ) 
            actions( CLEAR_CURRENT_PROJECT_ACTION, {} )
        }
    },[] )
    useEffect(() => {
        actionsMain(GET_SINGLE_ACTIONS, {data_type: "PEFestProject", id, land_id: festId } )
            .then( (res: IProject) => {  
                //console.log( res )
                useFestProjects.getState().setCurrentProject( res )
                useDataStore.getState().PEFestCritery.forEach(( critery: ICritery ) => {
                    createEmptyRating( critery )
                })
                setIsLoading(false)
            })
    }, [ isLoading1 ] )

    useEffect(() => {
        if(theme === THEME_NAMES.NIGHT) {
            addCSSClass(
                'fest-project-theme', 
                `
                .milestone-button.milestone-label-success {
                    background-color: #7aea9cbd;
                }
                .milestone-button.milestone-label-primary {
                    background-color: #7ab9eacc;
                }
                .milestone-button .milestone-button-label.text-dark-primary,
                .milestone-button.milestone-label-success .text-dark-success {
                    color: #FFFFFF!important; 
                }
                .milestone-button.active .milestone-button-label.text-dark-primary { 
                    color: #6790bd !important; 
                } 
                .milestone-button.milestone-label-success.active .text-dark-success {
                    color: #538d60 !important;
                }
                `
            )
        }
        else {
            removeCSSClass( 'fest-project-theme' ) 
        }
    }, [theme])
    if( isLoading ) {
        return <Loading />
    } 
    return width >= WINDOW_WIDTH.TABLET
        ?
        <ProjectScreenForm />
        :
        <ProjectMobileForm />
}
export default ProjectForm