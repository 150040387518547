import { useDataStore } from "src/modules/pe-basic-module/data/store"
import { useEffect } from "react"
import { IPartner } from "../data/interfaces"
import ToposPartner from "./ToposPartner"
import actions from "src/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "src/modules/pe-basic-module/data/actionTypes"
import { ID } from "src/libs/interfaces/layouts"
import { IFestDataStor, useFestDataStore } from "src/modules/pe-fest-module/data/store"

/* Protopia Ecosystem component */
export interface ILandPartnersWidgetProps {
   
}
const LandPartnersWidget = (props: ILandPartnersWidgetProps): JSX.Element => {
    const partners: IPartner[] = useDataStore((state: any) => state.PEToposPartner ) || []
    const land_id: ID = useFestDataStore((state: IFestDataStor) => state.festId)
    useEffect(() => {
        console.log( land_id )
        actions(GET_FEED_ACTION, {data_type: "PEToposPartner", land_id})
    }, [ land_id ])
    return <div className='pe-land-partners-widget-container container flex-centered flex-wrap'>
        {
            partners.map(p => <ToposPartner item={p} />)
        }
    </div>
}
export default LandPartnersWidget