import { Role } from "@/libs/interfaces/layouts"
import { IProject } from "../interfaces"

export interface ITeamMember {
    id: keyof IProject
    name: string
    role: Role
}

export const team = (): ITeamMember[] => {
    return [
        {
            id: "tutor",
            name: "Tutor",
            role: "Tutor",
        },
        {
            id: "leader",
            name: "Project_author",
            role: "Project_author",
        },
        {
            id: "member0",
            name: "Member 0",
            role: "Project_member",
        },
        {
            id: "member1",
            name: "Member 1",
            role: "Project_member",
        },
        {
            id: "member2",
            name: "Member 2",
            role: "Project_member",
        },
        {
            id: "member3",
            name: "Member 4",
            role: "Project_member",
        },
        {
            id: "member5",
            name: "Member 6",
            role: "Project_member",
        },
        {
            id: "member7",
            name: "Member 7",
            role: "Project_member",
        },
        {
            id: "member8",
            name: "Member 8",
            role: "Project_member",
        },
        {
            id: "member9",
            name: "Member 9",
            role: "Project_member",
        },
    ]
}