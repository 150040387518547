import React from 'react' 
import { useMainStore, IState } from "@/settings/zustand"
import { LayoutIcon } from '@/libs/useful'
import { IUser } from '@/settings/zustand'

const UserAvatar = (props: any): JSX.Element => {
    
    const user: IUser = useMainStore(( state: IState ) => state.user) 
    return user.avatar
        ?
        <div className="position-relative">
            <LayoutIcon 
                onClick={props.onClick}
                width={props.width}
                height={props.height}
                src={ !user.avatar ? "/assets/img/user1.svg" : "" }
                style={ user.avatar ? { backgroundImage: `url(${user.avatar})`, backgroundSize: "cover" } : {} }
                className={ `user-ava ${ props.noPadding ? "m-0" : "" }` }
            />
            <div className="position-absolute bottom right micro">
                <i className={`fab fa-${user.external}`}></i>
            </div>
        </div>
        
        :
        <LayoutIcon
            onClick={props.onClick}
            src={ "fas fa-user " }
            className="mx-3 small "
        /> 
} 

export default UserAvatar