
import {  DocumentNode } from "graphql"
import { useAppoloStore, useMainStore } from "../../../settings/zustand" 
import { 
    getChangeName,
    getInputTypeName,
    getMutationArgs,
    getQueryArgs, 
    getQueryName, 
    mutationEdit, 
    querySingle, 
    querySingleName, 
} from "../../../libs/layouts" 
import { 
    ADD_ATTACHMENT_ACTION,
    CHANGE_CARD_SORT_ACTION,
    CHANGE_CARD_TYPE_ACTION,
    CLEAR_FESTIVAL_PRESCEPTIONS_ACTION,
    COMPARE_CURRENT_PROJECT_ACTION,
    COPY_LAND_ACTION,
    CORRECT_PROJECT_RATING_ACTION,
    DELETE_ATTACHMENT_ACTION,
    DELETE_EXHORTATION_ACTION,
    FET_FESTIVAL_EXTENDS_ACTION,
    GET_FEED_ACTION,
    GET_FEED_COUNT_ACTION, 
    GET_FESTIVAL_COMMUNICATION_ACTION, 
    GET_FESTIVAL_DATA_ACTION, 
    GET_FESTIVAL_SETTINGS_ACTION, 
    GET_FEST_DIARIES_ACTIONS, 
    GET_FULL_STATISTICS_ACTION, 
    GET_HONNOR_HALL_ACTIONS, 
    GET_OWN_ACTION, 
    GET_PROJECT_DIARY_ACTION, 
    GET_PROJECT_MILESTONE_ACTION, 
    GET_SINGLE_ACTIONS, 
    GET_UNVERIFIED_ACTION, 
    REGISTER_NEW_FESTIVAL, 
    REGISTER_PROJECT_ACTION, 
    SEND_EXHORTATION_ACTION, 
    SET_FILTERS_ACTIONS,
    SNAPSHOT_LAND_ACTION,
    UDATE_PROJECT_RATING_ACTION,
    UPDATE_PROJECT_HONEYCOMBS,
    UPDATE_SINGLE_ACTION
} from "./actionTypes" 
import { GET_FESTIVAL } from "./graphql/GET_FESTIVAL"
import { GET_HONNOR_HALL } from "./graphql/GET_HONNOR_HALL"
import { GET_OWN } from "./graphql/GET_OWN"
import { CHANGE_PROJECT_HONEYCOMBS } from "./graphql/CHANGE_PROJECT_HONEYCOMBS"
import { GET_PROJECT_MILESTONE } from "./graphql/GET_PROJECT_MILESTONE"
import { DELETE_ATTACHMENT } from "./graphql/DELETE_ATTACHMENT"
import { ADD_ATTACHMENT } from "./graphql/ADD_ATTACHMENT"
import { COMPARE_CURRENT_PROJECT } from "./graphql/COMPARE_CURRENT_PROJECT"
import { UPDATE_PROJECT_RATING } from "./graphql/UDATE_PROJECT_RATING"
import { SEND_EXHORTATION } from "./graphql/SEND_EXHORTATION"
import { GET_DIARIES } from "./graphql/GET_DIARIES"
import { GET_PROJECT_DIARY } from "./graphql/GET_PROJECT_DIARY"
import { CLEAR_FESTIVAL_PRESCEPTIONS } from "./graphql/CLEAR_FESTIVAL_PRESCEPTIONS"
import { REGISTER_PROJECT } from "./graphql/REGISTER_PROJECT"
import { DELETE_EXHORTATION } from "./graphql/DELETE_EXHORTATION"
import { GET_UNVERIFIED } from "./graphql/GET_UNVERIFIED"
import { SNAPSHOT_LAND } from "./graphql/SNAPSHOT_LAND"

const { gql } = require("@apollo/client/core")

const asyncControllers =  async(action:string, data: any) => {
    let response : any,  
        _query: string,
        query : DocumentNode,
        query_name : string, fields : any,
        qstr: string,
        site: string
        
    const apolloClient: any = useAppoloStore.getState().client
    switch( action )
    {
        case REGISTER_PROJECT_ACTION:    
            response = await apolloClient.mutate({
                mutation : gql`${ REGISTER_PROJECT( data ) }` ,
                variables :  {
                    ...data
                }
            })
            return response  
        case UPDATE_SINGLE_ACTION:   
            const m: any = mutationEdit(
                data.data_type,                     // mutate data type
                getChangeName(data.data_type),      // GQL mutation name
                getInputTypeName(data.data_type),   // input type
                getMutationArgs(data.data_type),     // chenged arguments
                data.landId, 
            )   
            response = await apolloClient.mutate({
                mutation: m,
                variables: {
                    id: data.id,
                    input: data.item,
                    land_id: data.landId 
                },
            })
            return response     
        case CLEAR_FESTIVAL_PRESCEPTIONS_ACTION: 
            response = await apolloClient.mutate({
                mutation : gql`${ CLEAR_FESTIVAL_PRESCEPTIONS( data ) }` ,
                variables :  {
                    land_id: data.land_id 
                }
            })
            return response 
        case GET_PROJECT_DIARY_ACTION: 
            response = await apolloClient.mutate({
                mutation : gql`${ GET_PROJECT_DIARY( data.id ) }`,
                variables :  {
                    land_id: data.land_id 
                } 
            })
            return response 
        case GET_FULL_STATISTICS_ACTION:  
            const project_name = getQueryName("PEFestProject")
            const project_fields = getQueryArgs("PEFestProject") 
            const user_name = getQueryName("User")
            const user_fields = getQueryArgs("User") 
            const rating_name = getQueryName("PEFestRating")
            const rating_fields = getQueryArgs("PEFestRating") 
            const siteStr1: string =  data.land_id
                ?
                ` ( land_id: "${ data.land_id }" ) `
                :
                ``
            const siteStr2: string =  data.land_id
                ?
                ` land_id: "${ data.land_id }" `
                :
                ``
            const queryStr: string = `query data
                { 
                    ${ project_name } ${ siteStr1 } 
                    {
                        ${ project_fields }
                    }
                    ${ user_name } ( paging: { count: -1}  ${ siteStr2 } )
                    {
                        ${ user_fields }
                    }
                    ${ rating_name } ${ siteStr1 } 
                    {
                        ${ rating_fields }
                    }
                }`
            /// console.log( data )
            /// console.log( queryStr )
            query = gql`${queryStr}`
            response = await apolloClient.query({ query })
            return response
        case SEND_EXHORTATION_ACTION:  
            response = await apolloClient.mutate({
                mutation : gql`${ SEND_EXHORTATION( data ) }`,
                variables :  {
                    land_id: data.land_id 
                } 
            })
            return response
        case DELETE_EXHORTATION_ACTION:  
            response = await apolloClient.mutate({
                mutation : gql`${ DELETE_EXHORTATION( data ) }`,
                variables :  {
                    land_id: data.land_id 
                } 
            })
            return response
        case UDATE_PROJECT_RATING_ACTION: 
            response = await apolloClient.mutate({
                mutation : gql`${ UPDATE_PROJECT_RATING( data ) }` ,
                variables :  {
                    land_id: data.land_id 
                }
            })
            return response
        case CORRECT_PROJECT_RATING_ACTION: 
            response = await apolloClient.mutate({
                mutation : gql`mutation correctPEFestProjectRating {
                    correctPEFestProjectRating(
                      land_id: "${data.landId}"
                      id: "${data.id}"
                      input: {
                        rate: ${data.rate},
                        unixtime: "${data.unixtime}",
                        user: "${data.user}",
                        display_name: "${data.display_name}",
                        description: "${data.description}"
                      }
                    ) {
                      rate
                      user
                      display_name
                      description
                    }
                  }` 
            })
            return response
        case COMPARE_CURRENT_PROJECT_ACTION: 
            response = await apolloClient.query({
                query : gql`${COMPARE_CURRENT_PROJECT(data.id, data.land_id)}`,
                variables :  {
                    land_id: data.land_id 
                } 
            })
            return response
        case GET_HONNOR_HALL_ACTIONS:
            response = await apolloClient.query( { 
                query: gql`${ GET_HONNOR_HALL( data.land_id ) }`,
                variables :  {
                    land_id: data.land_id 
                } 
            } )
            return response 
        case GET_FEST_DIARIES_ACTIONS: 
            response = await apolloClient.query( { 
                query: gql`${ GET_DIARIES(data.land_id) }`,
                variables :  {
                    land_id: data.land_id 
                } 
            } )
            return response 
        case GET_OWN_ACTION:
            //console.log( "controller.GET_OWN_ACTION:", data.land_id) 
            response = await apolloClient.query( { 
                query: gql`${ GET_OWN(data.land_id) }`,
                variables :  {
                    land_id: data.land_id 
                }
            } )
            return response
        case GET_UNVERIFIED_ACTION:
            //console.log( "controller.GET_OWN_ACTION:", data.land_id) 
            response = await apolloClient.query( { 
                query: gql`${ GET_UNVERIFIED(data.land_id) }`,
                variables :  {
                    land_id: data.land_id 
                }
            } )
            return response
        case GET_FESTIVAL_DATA_ACTION:
            const site_name = querySingleName("Land") 
            const site_fields = getQueryArgs("Land")
            site = data.id
                ?
                ` (land_id: "${data.id}")`
                :
                ``

            qstr = `
                query getFestivalCache
                {  
                    ${ site_name } (id: "${data.id}") 
                    {
                        ${ site_fields }
                    }
                    ${ GET_FESTIVAL( data.id ) }
                }
                `
            //console.log(  GET_FESTIVAL( data.id ) )
            query = gql`${ qstr }`
            response = await apolloClient.query({ query })
            return response
        case FET_FESTIVAL_EXTENDS_ACTION:
            const tracks_name = getQueryName("PEFestTrack")
            const tracks_fields = getQueryArgs("PEFestTrack")
            const destrict_name = getQueryName("PEFestDestrict")
            const destrict_fields = getQueryArgs("PEFestDestrict")
            const honeycombs_name = getQueryName("PEFestHoneycombs")
            const honeycombs_fields = getQueryArgs("PEFestHoneycombs") 
            const ganres_name = getQueryName("PEFestGanre")
            const ganres_fields = getQueryArgs("PEFestGanre") 
            const milestones_name = getQueryName("PEFestMilestone")
            const milestones_fields = getQueryArgs("PEFestMilestone") 
            const categories_name = getQueryName("PEFestCategory")
            const categories_fields = getQueryArgs("PEFestCategory") 
            const criteries_name = getQueryName("PEFestCritery")
            const criteries_fields = getQueryArgs("PEFestCritery")   
            const schools_name = getQueryName("PEFestSchool")
            const schools_fields = getQueryArgs("PEFestSchool")  
            site = data.id
                ?
                ` (land_id: "${data.id}")`
                :
                ``

            qstr = `
                query getFestivalExtendsCache
                { 
                    ${ tracks_name } ${site}
                    {
                        ${ tracks_fields }
                    }
                    ${ destrict_name } ${site}
                    {
                        ${ destrict_fields }
                    }
                    ${ schools_name } ${site}
                    {
                        ${ schools_fields }
                    }
                    ${ honeycombs_name } ${site}
                    {
                        ${ honeycombs_fields }
                    }
                    ${ ganres_name } ${site}
                    {
                        ${ ganres_fields }
                    }
                    ${ milestones_name } ${site}
                    {
                        ${ milestones_fields }
                    }
                    ${ categories_name } ${site}
                    {
                        ${ categories_fields }
                    }
                    ${ criteries_name } ${site}
                    {
                        ${ criteries_fields }
                    }
                }
                `
            //console.log(  GET_FESTIVAL( data.id ) )
            query = gql`${ qstr }`
            response = await apolloClient.query({ query })

            return response
        case GET_FESTIVAL_SETTINGS_ACTION:
            qstr = `query getPEFestival {
                ${ GET_FESTIVAL( data.id, data.fields ) }
            }` 
            query = gql`${qstr}`
            response = await apolloClient.query({ query })
            return response 
        case GET_FESTIVAL_COMMUNICATION_ACTION:
            query = gql`query getFestivalCommunications {
                getPEFestival(id:-1) {
                    id
                    newProjectEmail
                    newProjectMessage
                }
            }`
            response = await apolloClient.query({ query })
            return response
        case COPY_LAND_ACTION:
            let input:any[] = []
            Object.keys(data).forEach( key => {
                const str = key === "event_types"
                    ?
                    `event_types: ["${data.event_types.join('","')}"]`
                    :
                    `${key}: "${data[key]}"`
                input.push(str) 
            })
            let __query = `mutation registerLand {
                registerLand(
                    input: {${input.join(", ")}}
                ){
                    id
                    name 
                    event_types
                    description
                }
            }` 
            // console.log( __query )
            query = gql`${ __query }`
            response = await apolloClient.mutate({ query })
            return response
        case SNAPSHOT_LAND_ACTION:
            // console.log( data )
            _query = SNAPSHOT_LAND(data)
            query = gql`${ _query }`
            response = await apolloClient.query({ 
                query, 
                variables: { land_id: data.land_id }
            })
            return response
        case REGISTER_NEW_FESTIVAL:  
            _query = `mutation registerNewPEFestival($new_title: String, $new_domain: String, $peland: String) { 
    registerNewPEFestival(
        new_title:$new_title, 
        new_domain: $new_domain, 
        peland: $peland
    ) 
}`
            console.log(data)
            response = await apolloClient.mutate({ 
                mutation: gql`${_query}`,
                variables:  data
            })
            return response
        case GET_FEED_ACTION:
            query_name = getQueryName(data.data_type)
            fields = getQueryArgs(data.data_type) 
            query = gql`
                query ${query_name} {
                    ${query_name}( paging:{ count:${data.count || useMainStore.getState().pageCount}, offset:${ data.offset || 0 }, ${ data.paging || "" }  })
                    {
                        ${fields}
                    }
                }`
            response = await apolloClient.query({
                query,
                variables: {
                    input: data
                }
            })
            return response
        case GET_FEED_COUNT_ACTION:
            query_name = `${querySingleName(data.data_type)}Count`  
            query = gql`
                query ${query_name} {
                    ${query_name} 
                }`
            response = await apolloClient.query({
                query,
                variables: {
                    input: data
                }
            })
            return response
        case CHANGE_CARD_TYPE_ACTION:
            response = new Promise(resolve => resolve( data.cardType ) )
            return response
        case CHANGE_CARD_SORT_ACTION:
            response = new Promise(resolve => resolve( data.cardSort ) )
            return response
        case ADD_ATTACHMENT_ACTION: 
            // console.log( ADD_ATTACHMENT( data ) )
            response = await apolloClient.mutate({
                mutation : gql`${ ADD_ATTACHMENT( data ) }`
            })
            return response
        case DELETE_ATTACHMENT_ACTION: 
            response = await apolloClient.mutate({
                mutation : gql`${ DELETE_ATTACHMENT( data ) }`
            })
            return response
        case GET_PROJECT_MILESTONE_ACTION: 
            response = await apolloClient.query({
                query : gql`${ GET_PROJECT_MILESTONE( data.id, data.land_id ) }`,
                variables :  {
                    land_id: data.land_id 
                }
            })
            return response
        case GET_SINGLE_ACTIONS:
            query_name = querySingleName( data.data_type )
            fields = getQueryArgs( data.data_type ) 
            query = querySingle( data.data_type, query_name, fields, data.id, data.land_id )
            response = await apolloClient.query({
                query,
                variables: {
                    id: data.id,
                    land_id: data.land_id
                }
            })
            return response
        case UPDATE_PROJECT_HONEYCOMBS:
            response = await apolloClient.mutate({
                mutation: gql`
                    ${CHANGE_PROJECT_HONEYCOMBS( data.projectId, data.honeycombsId )}
                ` ,
                variables :  {
                    land_id: data.land_id 
                }
            })
            
            return response
        case SET_FILTERS_ACTIONS:
            // query_name  = querySingleName( "PEFestProject" )
            // fields      = getQueryArgs( "PEFestProject" )
            return response
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers