export const GET_SINGLE_ACTIONS: string = 'getSingle' 
export const GET_FEED_ACTION: string = 'getFeed' 
export const GET_FEED_COUNT_ACTION: string = 'getFeedCount' 
export const GET_FESTIVAL_SETTINGS_ACTION: string = 'getFestivalSettings' 
export const GET_FESTIVAL_DATA_ACTION: string = 'getFestivalData' 
export const FET_FESTIVAL_EXTENDS_ACTION: string = 'getFestivalExtends' 
export const GET_FESTIVAL_COMMUNICATION_ACTION: string = 'getFestivalCommuniction' 
export const CHANGE_CARD_TYPE_ACTION: string = 'changeCardType' 
export const CHANGE_CARD_SORT_ACTION: string = 'changeCardSort' 
export const GET_HONNOR_HALL_ACTIONS: string = 'getHonorHall' 
export const GET_FEST_DIARIES_ACTIONS: string = 'getFestDiaries' 
export const SET_FILTERS_ACTIONS = "setFilter"
export const GET_OWN_ACTION = "getOwnProjects"
export const GET_UNVERIFIED_ACTION = "getUnverifiedProjects"
export const UPDATE_PROJECT_HONEYCOMBS = "updateProjectHoneycombs"
export const GET_PROJECT_MILESTONE_ACTION = "getProjectMilestone"
export const ADD_ATTACHMENT_ACTION = "addAttachment"
export const COMPARE_CURRENT_PROJECT_ACTION = 'compareCurrentProject'
export const DELETE_ATTACHMENT_ACTION = "deleteAttachment"
export const CLEAR_CURRENT_PROJECT_ACTION = "clearCurrentProject"
export const UDATE_PROJECT_RATING_ACTION = "updateProjectRating"
export const CORRECT_PROJECT_RATING_ACTION = "correctProjectRating"
export const SEND_EXHORTATION_ACTION = "sendExhotation"
export const DELETE_EXHORTATION_ACTION = "deleteExhotation"
export const GET_FULL_STATISTICS_ACTION = "getFullStatistic"
export const GET_PROJECT_DIARY_ACTION = "getProjectDiary"
export const CLEAR_FESTIVAL_PRESCEPTIONS_ACTION = "clearFestivalPresceptions"

export const UPDATE_SINGLE_ACTION = 'updateSingle'
export const REGISTER_PROJECT_ACTION = 'registerProject' 
export const COPY_LAND_ACTION = 'copyLand' 
export const SNAPSHOT_LAND_ACTION = 'snapshotLand' 
export const REGISTER_NEW_FESTIVAL = 'registerNewFestival' 