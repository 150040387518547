import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Callout } from "@blueprintjs/core" 
import { __ } from "@/libs/utilities"  
import { LoaderBallsLine, Loading, Pagi } from "@/libs/useful"
import actions from "../../data/actions"
import { GET_FEED_ACTION } from '../../data/actionTypes'
import { IState, useMainStore } from "../../../../settings/zustand"
import FeedComponent from "./FeedComponent" 
import FeedLayout from "./FeedLayout"
import { useDataStore } from "../../data/store"
import { LoaderMinimal } from "@/libs/useful/Loading"
import { ID } from "@/libs/interfaces/layouts"
  
export interface IFeedProps {
  layoutComponent? : any
  component?: any
  data_type: string
  is_hide_pagi?: boolean
  setOffset?: (offset: number) => void
  offset: number
  count?: number
  height?: number
  paging?: string
  land_id?: ID
  class_name?: string
  containerClassName?: string
  style?: any
  params?: any
  topPagiHide?: boolean
  isLoader?: boolean
  bottomPagiHide?: boolean, 
  order?: any
  filter?: ID[]
  isEmptyComment?: string
  feedBefore?: any[]
  onLoading?: (items: any[],  offset: number, count: number, paging: string, fullCount: number) => void
  items? : any[] 
}

const Feed = (props: IFeedProps) => {

  const pageCount: number = useMainStore( ( state: IState ) => state.pageCount )
  //const [items, setItems] = useState<any[]>( props.items || [] )
  const items : any[] = useDataStore((state: any) => state[props.data_type] || [] )
  const [fullCount, setFullCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>( true )
  const [offset, setOffset] = useState<number>( props.offset )
  
  const addBefore = (): any[] => {
    if( Array.isArray(props.feedBefore) ) {
      return props.feedBefore
    }
    return []
  }

  useEffect(() => {
    if(Array.isArray( props.items ) ) {
      //setItems( props.items )
    }
  }, [props.items] )

  const feed: JSX.Element = useMemo( () => {
    const FeedElementComponent = props.component ? props.component : FeedComponent
    return Array.isArray(items) && items.length > 0 
      ?
      <>        
        { 
          [
            ...addBefore(), 
            ...(
              Array.isArray( props.filter )
                ?
                items.filter( item => !props.filter?.includes( item.id ) )
                :
                items
            )
          ].map(( item: any, i: number ) => {
            return <FeedElementComponent 
              item={item} 
              i={i}
              data_type={ props.data_type }
              params={ props.params }
              height={ props.height }
              key={item.id}
            />
          })
        } 
      </>
      :
      <td colSpan={30} className="w-100">
        <Callout className={`p-5 text-center ${props.containerClassName}`}>
          {__(props.isEmptyComment ? props.isEmptyComment : "No elements exists")}
        </Callout>
      </td>
      
    }, 
    [ 
      items, 
      props.params, 
      props.filter, 
      props.component, 
      props.containerClassName, 
      props.data_type, 
      props.height, 
      props.offset,
      offset
    ]
  ) 
  
  const switchFeed = ( newOffset: number ) => {
    // console.log( "offset:", newOffset, "land_id:", props.land_id )
    if(!props.data_type) return
    actions(
      GET_FEED_ACTION, 
      { 
        data_type: props.data_type, 
        offset: newOffset, 
        count: pageCount, 
        paging: props.paging || "",
        land_id: props.land_id
      })
          .then(resp => {
            const items : any[] = [ ...addBefore(), ...resp.feed]
            // setItems( items )
            if(props.onLoading) {
              props.onLoading( items, newOffset, pageCount, props.paging || "", resp.count )
            }
            setFullCount(resp.count)
            setIsLoading(false)
          })
  } 
  useEffect(() => {
    setOffset( 0 )
    setFullCount( 0 )
    //setItems( [] )
  }, [ props.data_type ])
 
  useEffect(() => {
    setIsLoading(true) 
  }, [ props.data_type ])

  useEffect(() => {
    setIsLoading(true)  
    switchFeed( offset )
  }, [ props.paging, props.data_type])

  useEffect(() => {
    setIsLoading(true)
    switchFeed( offset )
  }, [ ])
 
  useEffect(() => { 
    setOffset( props.offset )
    //console.log("OFFSET", props.offset, offset)
    switchFeed( props.offset )
  }, [ props.offset ]) 
  
  if (!props.data_type) {
    return <Callout>
      {__("Unknown data type")}
    </Callout>
  }  
  const getShifter = (obj:any={}): JSX.Element | null =>
  { 
    return pageCount && pageCount < fullCount && !props.is_hide_pagi
        ?
          <div className="py-1 d-flex pe-pagi ">
            <Pagi
              all={Math.ceil(fullCount / pageCount) - 1}
              current={Number( offset ) / pageCount}
              onChoose={ onPagi }  
              marginPagesDisplayed={ 4 }
              {...obj}
            />
          </div>
        : 
        null
  }
  const onPagi = ( n: number ): void => {
    setIsLoading( true )
    const newOffset: number = n * pageCount
    setOffset( newOffset )  
    switchFeed( newOffset )
    if( props.setOffset ) {
      props.setOffset( newOffset )
    }
  }

  
  return <div  className={`${props.containerClassName} `} >
    <div className="d-md-flex justify-content-between align-items-center "> 
      {
        !props.topPagiHide && <div className="my-3 w-100">{ getShifter() }</div>
      }
      {
        props.isLoader && isLoading && 
          <LoaderMinimal style={{ height: 14, display:"flex" }} />
      }
    </div>
    <div className="d-flex w-100 mb-5 ">
      <div className={`w-100 flex-grow-100 ${props.class_name}`}  style={ props.style }>
        <FeedLayout {...props} isLoading={isLoading}>
              {feed}
        </FeedLayout> 
      </div>        
      <div className="mobile-pagination-container" >
        <div style={{ position:"fixed", bottom: 110 }}>
          { getShifter({ containerClassName:"pagination-vertical", isHideNumerics: true }) }  
        </div>
      </div>
    </div>
    <div className="d-md-flex d-none justify-content-between align-items-center"> 
      {
        !props.bottomPagiHide && <div className="my-3 w-100">{ getShifter() }</div>
      } 
    </div>
  </div> 
}
 
export default Feed