
import { IAddFestivalTabsProps } from "../AddFestivalTabs"
import { Button, Intent } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface IPureTabProps {
   
}


const PureTab = (props: IAddFestivalTabsProps) : JSX.Element => {
    return <Button fill className="p-4" intent={Intent.DANGER}>
        {__("Create by pure")}
    </Button>
}

 
export default PureTab