import React, { FormEvent, useState } from 'react' 
import {useMainStore, IState} from 'src/settings/zustand'
import { __ } from '@/libs/utilities'
import FieldInput from '@/libs/scalars'
import actions from '../../data/actions'
import { UPDATE_CURRENT_USER } from "../../data/actionTypes"
import { Location, Navigate, useLocation, } from 'react-router'
import { LoaderLine } from '../../../../libs/useful'
import { SCALAR_TYPES } from '../../../../libs/scalars'

const ProfileForm = (props: any):React.ReactElement => {
    const user = useMainStore( (state: IState) => state.user )
    const location: Location = useLocation()
    return user.id
        ?
        <Profile />
        :
        <Navigate to="/login" replace state={{ pathname: location.pathname }} />
} 

export default ProfileForm

const Profile = ():React.ReactElement => {
    const user = useMainStore( (state: IState) => state.user)
    const [avatar, setAvatar] = useState( user.avatar )
    const [avatarName, setAvatarName] = useState( user.avatar )
    const [firstName, setFirstName] = useState( user.first_name )
    const [user_descr, setUser_descr] = useState( user.user_descr )
    const [lastName, setLastName] = useState( user.last_name )
    const [userEmail, setUserEmail] = useState( user.email )
    const [phone, setPhone] = useState( user.phone )
    const [password, setPassword] = useState( '' )
    const [isLoading, setIsLoading] = useState( false )

    const update = (evt:FormEvent) => {
        evt.preventDefault()
        evt.stopPropagation()
        setIsLoading(true)
        const userData: any = {}
        if(user_descr)  userData.user_descr = user_descr
        if(firstName)   userData.first_name = firstName
        if(lastName)    userData.last_name  = lastName
        if(userEmail)   userData.user_email = userEmail
        if(phone)       userData.phone      = phone
        if(password)    userData.password   = password
        if(avatar)      userData.avatar     = avatar
        if(avatarName)  userData.avatar_name = avatarName.name
        console.log( userData )
        actions(UPDATE_CURRENT_USER, userData).then(res => setIsLoading(false))
    }
    return <div className="row justify-content-center">
        <div className="col-md-7 text-center pl-4">
            <form
                onSubmit={ update }
            >
                <FieldInput
                    title="Avatar"
                    type={SCALAR_TYPES.MEDIA}
                    field="first_name"
                    style={{ borderRadius:130 }}
                    height={80}
                    width={80}
                    editable 
                    isURLHide 
                    isFileNameHide
                    limitSize={ 300000 }
                    accept="image/*"
                    value={ avatar }
                    onChange={ (value: any, file: any={}, name: string = "" ) => {
                        setAvatar( value ) 
                        setAvatarName( name )                       
                    }}
                />
                <FieldInput
                    title={__('Description')}
                    type={SCALAR_TYPES.HTML}
                    field="user_descr"
                    editable
                    value={ user_descr }
                    onChange={ (evt: any) => setUser_descr( evt ) }
                />
                <FieldInput
                    title={__('Firs name')}
                    type={SCALAR_TYPES.STRING}
                    field="first_name"
                    editable
                    value={ firstName }
                    onChange={ (evt: any) => setFirstName( evt ) }
                />
                <FieldInput
                    title={__('Last name')}
                    type={SCALAR_TYPES.STRING}
                    field="last_name"
                    editable
                    value={lastName}
                    onChange={ (evt: any) => setLastName(evt)}
                />
                <FieldInput
                    title="E-mail"
                    type={SCALAR_TYPES.EMAIL}
                    field="user_email"
                    editable
                    value={userEmail}
                    onChange={ (evt: any) => setUserEmail(evt)}
                />
                <FieldInput
                    title={__('Phone')}
                    type={SCALAR_TYPES.PHONE}
                    field="phone"
                    editable
                    value={ phone }
                    onChange={ (evt: any) => setPhone(evt)}
                />
                <FieldInput
                    title={__('Change password')}
                    type={SCALAR_TYPES.PASSWORD}
                    field="password"
                    editable
                    value={ password }
                    onChange={ (evt: any) => setPassword(evt)}
                />
                <div className='row'>
                    <div className='cpl-md-9 offset-md-3'>
                    {
                        isLoading
                            ?
                            <LoaderLine />
                            :
                            <input
                                type="submit"
                                className="btn btn-secondary my-3"
                                value={__("Update")}
                            />
                    }    
                    </div>                
                </div>
                 
            </form>
        </div>
    </div>
}