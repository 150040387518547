import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_ACTION } from "@/modules/pe-fest-module/data/actionTypes"
import { IProject } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from "@/modules/pe-fest-module/data/store"
import { team } from "@/modules/pe-fest-module/data/utils/team"
import { isRole } from "@/settings/zustand/user"
import { Button, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IProjectTeamProps {
   
}
const ProjectTeam = (props: IProjectTeamProps): JSX.Element => {
    const [project, setProject] = useState<IProject>( useFestProjects.getState().currentProject )
    const [cur, setCur] = useState<string>( JSON.stringify( useFestProjects.getState().currentProject ) )
    const [prj, setPrj] = useState<string>( JSON.stringify( useFestProjects.getState().currentProject ) )
    const [isLoading, setIsLoading] = useState(false)   
    const landId = useFestDataStore((state: IFestDataStor) => state.festId ) 
    useEffect(() => useFestProjects.subscribe(
        (state: IFestProjectsProps) => {
            setProject(state.currentProject)
            setCur( JSON.stringify( state.currentProject ) )
        }
    ), [])
    const onValue = (value: any, field: keyof IProject) => {
        const newPr = {
            ...project,
            [field]: value
        }
        setProject( newPr )
        setPrj( JSON.stringify(newPr) )
    }
    const isSync = () => {
        return cur === prj
    }
    const onUpdate = () => {
        const data = {
            data_type : "PEFestProject",
            id: project.id,
            item: {
                tutor: project.tutor?.id     || "-1",
                leader: project.leader?.id   || "-1",
                member0: project.member0?.id || "-1",
                member1: project.member1?.id || "-1",
                member2: project.member2?.id || "-1",
                member3: project.member3?.id || "-1",
                member4: project.member4?.id || "-1",
                member5: project.member5?.id || "-1",
                member6: project.member6?.id || "-1",
                member7: project.member7?.id || "-1",
                member8: project.member8?.id || "-1",
                member9: project.member9?.id || "-1",
            },
            landId: landId
        }
        actions(UPDATE_SINGLE_ACTION, data)
            .then(
                (response) => {
                    console.log( response )
                    setIsLoading(false)     
                    AppToaster.show({
                        message: __("Success update")
                    })
                },
                () => {
                    setIsLoading(false)   
                    AppToaster.show({
                        message: __("Unknown error. Try later"),
                        intent: Intent.DANGER
                    })
                }
            )
    }
    return <div className='pe-project-team-container small-container'>
        {
            team().map(t => {
                return <FieldInput
                    key={t.id}
                    type={SCALAR_TYPES.EXTERNAL}
                    component="User"
                    editable={isRole(["Tutor", "Project_author"])}
                    vertical={VerticalType.HORIZONTAL}
                    value={ project[t.id] }
                    onChange={( value ) => onValue( value, t.id )}
                    title={ __( t.name ) }
                    filter={{
                        roles: [ t.role ]
                    }}
                    landId={ landId }
                />
            })
        }
        <div className="w-100 flex-centered mb-5">
            <Button 
                intent={isSync() ? Intent.NONE : Intent.DANGER}
                minimal={ isSync() } 
                disabled={ isSync() } 
                onClick={onUpdate}
            >
                {__("Update")}
            </Button>
        </div>        
    </div>
}
export default ProjectTeam

